@import './variables.scss';
@import './global.scss';


.hello {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer {
    text-align: end;
    padding-right: 2%;

    .footer_ver {
        font-size: 0.8em;
        padding-bottom: 1%;
    }
    .footer_date {
        font-size: 0.6em;
    }
}