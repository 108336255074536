// ----- variables -----

$bg-color-main: #fff;
$bg-color-inner: #ebeaea;

$font-color-main: #000000;

$border-color-main: #000;


